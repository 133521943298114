import axios from '@/axios.js'

const state = () => ({
    kelurahans: []
})

const mutations = {
    SET_KELURAHANS (state, payload) {
      state.kelurahans = payload
    },
    ADD_KELURAHAN (state, item) {
      state.kelurahans.unshift(item)
    },
    UPDATE_KELURAHAN (state, kelurahan) {
      const kelurahanIndex = state.kelurahans.findIndex((p) => p.id === kelurahan.id)
      Object.assign(state.kelurahans[kelurahanIndex], kelurahan)
    },
    REMOVE_KELURAHAN (state, itemId) {
      const ItemIndex = state.kelurahans.findIndex((p) => p.id === itemId)
      state.kelurahans.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchKelurahan ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/kelurahan`)
        .then((response) => {
          commit('SET_KELURAHANS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addKelurahan ({ commit }, kelurahan ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', kelurahan)
        .then((response) => {
          commit('ADD_KELURAHAN', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateKelurahan ({ commit }, kelurahan) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${kelurahan.id}`, kelurahan)
        .then((response) => {
            commit('UPDATE_KELURAHAN', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeKelurahan ({ commit }, kelurahan) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${kelurahan.id}`)
        .then((response) => {
            commit('REMOVE_KELURAHAN', kelurahan.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}